import { type AxiosRequestConfig } from 'axios';
import type { Route } from 'vue-router';
import {
    INTERNAL_SERVER_ERROR,
    HIGHEST_ALLOWED_RANGE,
    BUSINESS_DAYS_IN_WEEK,
    EIAIDS_WITH_STORAGE_SAVINGS,
} from '@/store/constants';
import { LOGGED_IN_STEPS } from '~/constants';

interface AverageTimeToQuote {
    max: number;
    min: number;
    med: number;
}

export const AXIOS_OPTIONS: AxiosRequestConfig = {
    validateStatus: (status) => status < INTERNAL_SERVER_ERROR,
};

export const getAvgTimeToQuoteString = (avgTimeToQuote: AverageTimeToQuote | null | undefined) => {
    let avgTimeToQuoteString = null;
    if (!avgTimeToQuote) {
        avgTimeToQuoteString = 'a few days';
    } else {
        const { max, min, med } = avgTimeToQuote;
        if (max - min > HIGHEST_ALLOWED_RANGE) {
            if (med > 1 && med <= BUSINESS_DAYS_IN_WEEK) {
                avgTimeToQuoteString = `${med} days`;
            } else if (med === 1) {
                avgTimeToQuoteString = '1 day';
            } else if (med === 0) {
                avgTimeToQuoteString = 'less than one day';
            } else if (med > BUSINESS_DAYS_IN_WEEK) {
                avgTimeToQuoteString = 'a few days';
            }
        } else if (max === min) {
            if (max > 1 && max <= BUSINESS_DAYS_IN_WEEK) {
                avgTimeToQuoteString = `${max} days`;
            } else if (max === 0) {
                avgTimeToQuoteString = 'less than one day';
            } else if (max === 1) {
                avgTimeToQuoteString = '1 day';
            } else if (max > BUSINESS_DAYS_IN_WEEK) {
                avgTimeToQuoteString = 'a few days';
            }
        } else if (max - min <= HIGHEST_ALLOWED_RANGE && max - min > 0) {
            if (min > 0 && max <= BUSINESS_DAYS_IN_WEEK) {
                avgTimeToQuoteString = `${min} to ${max} days`;
            } else if (min === 0 && max === 1) {
                avgTimeToQuoteString = '1 day';
            } else if (min === 0 && max === HIGHEST_ALLOWED_RANGE) {
                avgTimeToQuoteString = `${HIGHEST_ALLOWED_RANGE} days`;
            } else if (min >= BUSINESS_DAYS_IN_WEEK) {
                avgTimeToQuoteString = 'a few days';
            } else if (min < BUSINESS_DAYS_IN_WEEK && max > BUSINESS_DAYS_IN_WEEK) {
                avgTimeToQuoteString = `${min} days`;
            }
        } else {
            avgTimeToQuoteString = 'a few days';
        }
    }
    return avgTimeToQuoteString;
};

export const getMaxStorageSavings = (energyInformationAdministrationIDs: number[]): number | null => {
    const storageSavings = EIAIDS_WITH_STORAGE_SAVINGS.filter(({ eiaid }) =>
        energyInformationAdministrationIDs.includes(eiaid),
    )?.map(({ storage_savings_percent }) => storage_savings_percent);
    return storageSavings?.length ? Math.max(...storageSavings) : null;
};

export const routeRequiresLogin = (route: Route) => LOGGED_IN_STEPS.includes(route.name as string);
