/**
 * Refresh an expired ID token by redirecting the user to Flash UI.
 */
import { Context } from '@nuxt/types';
import { jwtDecode } from 'jwt-decode';
import { COMPONENTS } from '~/constants';

export enum TokenStatus {
    MISSING,
    INVALID,
    EXPIRED,
    VALID,
    NOT_VERIFIED,
}
export function getFlashTokenClaims(idToken: string): {
    'custom:flash_user_id'?: string;
    exp?: number;
    email_verified?: boolean;
} {
    const decodedClaims = jwtDecode<{ exp?: number; email_verified?: boolean }>(idToken);
    return decodedClaims;
}
export function checkFlashIdToken(idToken: string): TokenStatus {
    if (!idToken) {
        // ID token cookie not set, user is not logged in
        return TokenStatus.MISSING;
    }

    let idTokenClaims;
    try {
        // TODO: Do proper token validation?
        // It adds overhead, but might avoid poor UX of logout() in FlashExpirationMiddleware.
        // See https://github.com/auth0/node-jsonwebtoken and https://github.com/auth0/node-jwks-rsa,
        // or https://github.com/awslabs/aws-jwt-verify.
        idTokenClaims = jwtDecode<{ exp?: number; email_verified?: boolean }>(idToken);
    } catch {
        // ID token is set, but can't be decoded
        return TokenStatus.INVALID;
    }

    if (!idTokenClaims.exp) {
        return TokenStatus.INVALID;
    }

    // Set expiry 1 hour earlier to allow for gap between this check and when the token is used
    const idTokenExpiry = idTokenClaims.exp - 60 * 60;
    if (idTokenExpiry * 1000 < Date.now()) {
        return TokenStatus.EXPIRED;
    }

    // HACK: Allow refreshing with an otherwise valid token (e.g. for testing). In DevTools:
    // localStorage.setItem('refreshFlash', 1); location.reload()
    // Not using a query param because of the complexity of removing it before setting next=.
    if (localStorage.getItem('refreshFlash')) {
        localStorage.removeItem('refreshFlash');
        return TokenStatus.EXPIRED;
    }
    return idTokenClaims.email_verified ? TokenStatus.VALID : TokenStatus.NOT_VERIFIED;
}

export default ({ $cookies, redirect, $config, route }: Context) => {
    // Skip over this behavior if we are in development.
    if (process.env.NODE_ENV === 'development') {
        return;
    }

    const idToken = $cookies.get('flash.id_token');
    const tokenStatus = checkFlashIdToken(idToken);

    // If the user's email is not verified, they should get redirected to VerifyComplete via
    // email-verification middleware. Disable the redirect in that case.
    if (idToken && tokenStatus === TokenStatus.NOT_VERIFIED && route.path.includes(COMPONENTS.VERIFY_COMPLETE)) {
        return;
    }

    // If a user has a flash token and the value is not valid, redirect them to the login page
    // to refresh their token or log in again.
    if (idToken && tokenStatus !== TokenStatus.VALID) {
        const loginUrl = `${$config.ACCOUNTS_DOMAIN}/login/`;
        const refreshUrl = `${loginUrl}?refresh=1&next=${encodeURIComponent(window.location.href)}`;
        redirect(refreshUrl);
    }
};
